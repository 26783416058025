import { Stack, Text } from '@mantine/core';
import React from 'react';

const RegistrationSuccess = () => {
  return (
    <Stack mt="sm" spacing="xs">
      <Text weight="bold">Вы успешно создали профиль!</Text>
      <Text>
        Вам поступит SMS сообщение с ссылкой на скачивание мобильного приложения “ЯПомогу эксперт”.
      </Text>
      <Text>
        При авторизации в приложении введите номер телефона ранее указанный в этой анкете.
      </Text>
      <Text>
        Если возникли трудности, свяжитесь со своим менеджером или сервисной поддержкой{' '}
        <Text component="a" href={'mailto:support@yapomogu.pro'} variant={'link'}>
          support@yapomogu.pro
        </Text>
      </Text>
    </Stack>
  );
};

const RegistrationFailed = () => {
  return (
    <Stack mt="sm" spacing="xs">
      <Text weight="bold">Не удалось создать профиль!</Text>
      <Text>
        Возможно такой пользователь уже существует. Свяжитесь со своим менеджером или сервисной
        поддержкой{' '}
        <Text component="a" href={'mailto:support@yapomogu.pro'} variant={'link'}>
          support@yapomogu.pro
        </Text>
      </Text>
    </Stack>
  );
};

type RegistrationResultProps = {
  variant: 'success' | 'failure';
};

export const RegistrationResult: React.FC<RegistrationResultProps> = ({ variant }) => {
  return (
    <>
      {variant === 'success' && <RegistrationSuccess />}
      {variant === 'failure' && <RegistrationFailed />}
    </>
  );
};
