import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MantineProvider } from '@mantine/core';
import 'dayjs/locale/ru';
// IDK what this actually does, but needed for datepicker???
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import './ym';
dayjs.extend(customParseFormat);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <MantineProvider withGlobalStyles withNormalizeCSS theme={{ colorScheme: 'light' }}>
      <App />
    </MantineProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
