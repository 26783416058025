import React from 'react';
import { Button, Group, NumberInput, Select, Space, Text, Textarea, Title } from '@mantine/core';
import specialties from '../constants/specialies.json';
import { ConfiguredForm } from './formConfiguration';
import { useMediaQuery } from '@mantine/hooks';

type PersonalInformationProps = {
  setStep: any;
  form: ConfiguredForm;
};

export const PersonalInformation: React.FC<PersonalInformationProps> = ({ setStep, form }) => {
  const isDesktop = useMediaQuery('(min-width: 576px)');
  const handleSubmit = () => {
    const validationResults = form.validate();
    const fieldsWeCareAbout = ['specialisation', 'experience', 'education'];
    let canContinue = true;
    fieldsWeCareAbout.forEach(key => {
      if (validationResults.errors[key]) {
        canContinue = false;
      }
    });

    if (!canContinue) return;
    // Go to next step
    form.clearErrors();
    setStep((prev: any) => prev + 1);
  };

  return (
    <>
      <Title order={3}>Расскажите о себе</Title>
      <Space h={'md'} />
      <Select
        required
        label="Специализация"
        placeholder="Ваша специализация"
        data={specialties}
        searchable
        description="Если в списке нет нужной специализации, выберите любую, и сообщите нам, чтобы мы могли создать нужную для вас."
        nothingFound="Ничего не найдено"
        {...form.getInputProps('specialisation')}
      />
      <Space h={'md'} />
      <NumberInput
        hideControls
        required
        label="Опыт работы"
        placeholder="Опыт работы"
        description="Укажите количество отработанных полных лет"
        max={100}
        min={1}
        rightSection={
          <div style={{ marginRight: 16 }}>
            <Text color="gray" size="sm">
              лет
            </Text>
          </div>
        }
        {...form.getInputProps('experience')}
      />
      <Space h={'md'} />
      <Textarea
        required
        autosize
        minRows={2}
        label="Образование"
        description="Укажите учебное заведение (не более 150 символов)"
        placeholder="Образование"
        {...form.getInputProps('education')}
      />
      <Space h={'md'} />
      <Textarea
        autosize
        minRows={3}
        label="О себе"
        description={
          'Расскажите на чем именно вы специализируетесь. Укажите дополнительное образование и все то, что поможет получить хорошие впечатление о вашем профиле.'
        }
        placeholder="О себе"
        {...form.getInputProps('about')}
      />
      <Group my="xl" position="right">
        <Button size="md" fullWidth={!isDesktop} color="blue" onClick={handleSubmit}>
          Продолжить
        </Button>
      </Group>
    </>
  );
};
