import React from 'react';
import {
  Button,
  Card,
  Group,
  NumberInput,
  TextInput,
  Select,
  Title,
  Text,
  Space,
  Grid,
  Checkbox,
} from '@mantine/core';
import { ConfiguredForm } from './formConfiguration';
import { useMediaQuery } from '@mantine/hooks';

type ChatTariffsProps = {
  form: ConfiguredForm;
  setStep: any;
};

const chatTariffsConfigs = [
  {
    title: 'Тариф для короткой консультации в чате',
    description:
      'Подберите такую длительность консультации, которой вам хватит для решения одного вопроса',
    description2:
      'Если вы не знаете какую стоимость указать, используйте стоимость вашей видеоконсультации за минусом 15-30% от ее стоимости',
    // Выбор: 30 минут, 1 час, 3 часа, 7 часов, 1 день, 3 дня, 5 дней, 1 неделя, 2 недели, 3 недели, 4 недели
    durationOptions: [
      { label: '30 минут', value: '30' },
      { label: '1 час', value: '60' },
      { label: '3 часа', value: '90' },
      { label: '7 часов', value: String(7 * 60) },
      { label: '1 день', value: String(24 * 60) },
      { label: '3 дня', value: String(3 * 24 * 60) },
      { label: '5 дней', value: String(5 * 24 * 60) },
      { label: '1 неделя', value: String(7 * 24 * 60) },
      { label: '2 недели', value: String(2 * 7 * 24 * 60) },
      { label: '3 недели', value: String(3 * 7 * 24 * 60) },
      { label: '4 недели', value: String(4 * 7 * 24 * 60) },
    ],
  },
  {
    title: 'Тариф для чата по подписке',
    description:
      'Чат по подписке - это чат когда клиенту нужно продолжительное время поддерживать коммуникацию с вами, например, в течении одного дня или нескольких недель',
    description2:
      'Если вы не знаете какую стоимость указать, возьмите стоимость вашей очной консультации и умножьте её на количество дней (длительности)',
    // Выбор: 1 день, 3 дня, 5 дней, 1 неделя, 2 недели, 3 недели, 4 недели
    durationOptions: [
      { label: '1 день', value: String(24 * 60) },
      { label: '3 дня', value: String(3 * 24 * 60) },
      { label: '5 дней', value: String(5 * 24 * 60) },
      { label: '1 неделя', value: String(7 * 24 * 60) },
      { label: '2 недели', value: String(2 * 7 * 24 * 60) },
      { label: '3 недели', value: String(3 * 7 * 24 * 60) },
      { label: '4 недели', value: String(4 * 7 * 24 * 60) },
    ],
  },
];

export const ChatTariffs: React.FC<ChatTariffsProps> = ({ form, setStep }) => {
  const isDesktop = useMediaQuery('(min-width: 576px)');
  const handleSubmit = () => {
    const validationResults = form.validate();
    // Different check because reasons.
    let canContinue = true;
    // That's some hacky check if error fields in format of chat.0.name are present
    if (Object.keys(validationResults.errors).some(key => key.includes('chat.'))) {
      canContinue = false;
    }

    if (!canContinue) return;
    // Go to next step
    form.clearErrors();
    setStep((prev: any) => prev + 1);
  };

  const requiredTariffConfig = chatTariffsConfigs[0];
  const optionalTariffConfig = chatTariffsConfigs[1];
  // Dirty :sigh:
  const isOptionalTariffRequired = form.values.chat[1].is_active;
  return (
    <>
      <Title order={3}>Настроим тарифы для консультаций в чате</Title>
      <Space h={'md'} />
      <Text color="gray" size="sm">
        Лучше настроить два тарифа, один для короткой консультации, другой для чата по подписке.
        Минимально нужно настроить один тариф.
      </Text>
      <Space h={'md'} />
      <Grid>
        {/* Required tariff */}
        <Grid.Col sm={12} lg={6}>
          <Card shadow="md" px="md">
            <Title order={4}>{requiredTariffConfig.title}</Title>
            <Text mt="xs" size={'sm'} color={'gray'}>
              {requiredTariffConfig.description}
            </Text>
            <Text mt="xs" size={'sm'} color={'gray'}>
              {requiredTariffConfig.description2}
            </Text>
            <Space h={'md'} />
            <TextInput
              label={'Название тарифа'}
              placeholder="Консультация"
              required
              {...form.getListInputProps('chat', 0, 'name')}
              // @ts-ignore Wrong library types for list fields.
              onFocus={() => form.clearFieldError(`chat.0.name`)}
            />
            <Space h={'md'} />
            <Select
              required
              label={'Длительность'}
              placeholder="Длительность"
              data={requiredTariffConfig.durationOptions}
              {...form.getListInputProps('chat', 0, 'duration')}
              // @ts-ignore Wrong library types for list fields.
              onFocus={() => form.clearFieldError(`chat.0.duration`)}
            />
            <Space h={'md'} />
            <NumberInput
              label={'Стоимость'}
              placeholder="Стоимость"
              required
              hideControls
              rightSection={
                <div style={{ marginRight: 16 }}>
                  <Text color="gray" size="sm">
                    ₽
                  </Text>
                </div>
              }
              {...form.getListInputProps('chat', 0, 'price')}
              // @ts-ignore Wrong library types for list fields.
              onFocus={() => form.clearFieldError(`chat.0.price`)}
            />
          </Card>
        </Grid.Col>

        {/* Optional Tariff*/}
        <Grid.Col sm={12} lg={6}>
          <Card shadow="md" px="md">
            <Title order={4}>{optionalTariffConfig.title}</Title>
            <Text mt="xs" size={'sm'} color={'gray'}>
              {optionalTariffConfig.description}
            </Text>
            <Text mt="xs" size={'sm'} color={'gray'}>
              {optionalTariffConfig.description2}
            </Text>
            <Space h={'md'} />
            <Checkbox
              label={'Мне нужен такой тариф'}
              color="blue"
              size="md"
              {...form.getListInputProps('chat', 1, 'is_active', { type: 'checkbox' })}
            />
            <Space h={'md'} />
            <TextInput
              label={'Название тарифа'}
              placeholder="Консультация"
              required={isOptionalTariffRequired}
              {...form.getListInputProps('chat', 1, 'name')}
              // @ts-ignore Wrong library types for list fields.
              onFocus={() => form.clearFieldError(`chat.1.name`)}
            />
            <Space h={'md'} />
            <Select
              required={isOptionalTariffRequired}
              label={'Длительность'}
              placeholder="Длительность"
              data={optionalTariffConfig.durationOptions}
              {...form.getListInputProps('chat', 1, 'duration')}
              // @ts-ignore Wrong library types for list fields.
              onFocus={() => form.clearFieldError(`chat.1.duration`)}
            />
            <Space h={'md'} />
            <NumberInput
              label={'Стоимость'}
              placeholder="Стоимость"
              required={isOptionalTariffRequired}
              hideControls
              rightSection={
                <div style={{ marginRight: 16 }}>
                  <Text color="gray" size="sm">
                    ₽
                  </Text>
                </div>
              }
              {...form.getListInputProps('chat', 1, 'price')}
              // @ts-ignore Wrong library types for list fields.
              onFocus={() => form.clearFieldError(`chat.1.price`)}
            />
          </Card>
        </Grid.Col>
      </Grid>
      <Group my="xl" position="right">
        <Button size="md" fullWidth={!isDesktop} color="blue" onClick={handleSubmit}>
          Продолжить
        </Button>
      </Group>
    </>
  );
};
