import React from 'react';
import { Button, Checkbox, Group, Title, Text, Divider, Grid, Space } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { ConfiguredForm } from './formConfiguration';
import { useMediaQuery } from '@mantine/hooks';

const indexToWeekday = [
  '',
  'В понедельник',
  'Во вторник',
  'В среду',
  'В четверг',
  'В пятницу',
  'В субботу',
  'В воскресенье',
];

type ScheduleProps = {
  form: ConfiguredForm;
  onSubmit: any;
};
export const Schedule: React.FC<ScheduleProps> = ({ form, onSubmit }) => {
  const isDesktop = useMediaQuery('(min-width: 576px)');

  const handleSubmit = () => {
    const validationResults = form.validate();
    if (!validationResults.hasErrors) {
      onSubmit();
    }
  };

  const fields = form.values.schedule.map((_, index) => {
    const checkboxProps = form.getListInputProps('schedule', index, 'is_active', {
      type: 'checkbox',
    });

    return (
      <React.Fragment key={index}>
        <Grid>
          <Grid.Col lg={3} sm={12}>
            <Checkbox
              style={{ minWidth: 180 }}
              label={[indexToWeekday[index + 1]]}
              color="blue"
              size="md"
              {...checkboxProps}
            />
          </Grid.Col>
          {!checkboxProps.checked && (
            <Grid.Col lg={9} sm={12}>
              <Text>не работаем</Text>
            </Grid.Col>
          )}
          {checkboxProps.checked && (
            <>
              <Grid.Col lg={2} sm={12}>
                <Text>начинаем в</Text>
              </Grid.Col>
              <Grid.Col lg={2} sm={12}>
                <TimeInput
                  {...form.getListInputProps('schedule', index, 'from')}
                  // @ts-ignore Wrong library types for list fields.
                  onFocus={() => form.clearFieldError(`schedule.${index}.from`)}
                />
              </Grid.Col>
              <Grid.Col lg={2} sm={12}>
                <Text>и заканчиваем в</Text>
              </Grid.Col>
              <Grid.Col lg={2} sm={12}>
                <TimeInput
                  {...form.getListInputProps('schedule', index, 'to')}
                  // @ts-ignore Wrong library types for list fields.
                  onFocus={() => form.clearFieldError(`schedule.${index}.to`)}
                />
              </Grid.Col>
            </>
          )}
        </Grid>
        <Divider my="sm" variant="dotted" />
      </React.Fragment>
    );
  });
  return (
    <>
      <Title order={3}>Последний шаг - ваше расписание</Title>
      <Space h={'md'} />
      {fields}
      {form.errors.schedule && <Text color={'red'}>{form.errors.schedule}</Text>}
      <Group my="xl" position="right">
        <Button size="md" fullWidth={!isDesktop} color="blue" onClick={handleSubmit}>
          Завершить
        </Button>
      </Group>
    </>
  );
};
