import React from 'react';
import { Button, Group, Space, TextInput, Title } from '@mantine/core';
import InputMask from 'react-input-mask';
import { ConfiguredForm } from './formConfiguration';
import { useMediaQuery } from '@mantine/hooks';

type AuthInformationProps = {
  form: ConfiguredForm;
  setStep: any;
};

export const AuthInformation: React.FC<AuthInformationProps> = ({ form, setStep }) => {
  const isDesktop = useMediaQuery('(min-width: 576px)');
  const handleSubmit = () => {
    const validationResults = form.validate();
    const fieldsWeCareAbout = ['full_name', 'phone', 'dob'];
    let canContinue = true;
    fieldsWeCareAbout.forEach(key => {
      if (validationResults.errors[key]) {
        canContinue = false;
      }
    });

    if (!canContinue) return;
    // Go to next step
    form.clearErrors();
    setStep((prev: any) => prev + 1);
  };

  const phoneInputProps = form.getInputProps('phone');
  const dobInputProps = form.getInputProps('dob');
  return (
    <>
      <Title order={3}>Данные для авторизации</Title>
      <Space h={'md'} />
      <TextInput
        required
        label="ФИО"
        placeholder="Фамилия Имя Отчество"
        {...form.getInputProps('full_name')}
      />
      <Space h={'md'} />
      {/*TODO: seems fucking hacky idk*/}
      <InputMask
        mask={'+7 (999) 999-99-99'}
        onChange={phoneInputProps.onChange}
        value={phoneInputProps.value}
      >
        {() => {
          return (
            <TextInput
              error={phoneInputProps?.error}
              required
              type="tel"
              label="Телефон"
              placeholder="+7 (000) 000-00-00"
              description="На указанный номер придет ссылка для загрузки приложения"
            />
          );
        }}
      </InputMask>
      <Space h={'md'} />
      <InputMask mask={'99.99.9999'} onChange={dobInputProps.onChange} value={dobInputProps.value}>
        {() => {
          return (
            <TextInput
              inputMode="numeric"
              error={dobInputProps?.error}
              required
              type="text"
              label="Дата рождения"
              placeholder="дд.мм.гггг"
            />
          );
        }}
      </InputMask>
      <Group my="xl" position="right">
        <Button size="md" fullWidth={!isDesktop} color="blue" onClick={handleSubmit}>
          Продолжить
        </Button>
      </Group>
    </>
  );
};
