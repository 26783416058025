import { Container, Group, Image, LoadingOverlay, RingProgress, Stack, Text } from '@mantine/core';
import { ConfiguredForm, useFormWithConfig } from './components/formConfiguration';
import { useEffect, useMemo, useState } from 'react';
import { PersonalInformation } from './components/PersonalInformation';
import { Schedule } from './components/Schedule';
import { AuthInformation } from './components/AuthInformation';
import dayjs from 'dayjs';
import { RegistrationResult } from './components/RegistrationResult';
import { ChatTariffs } from './components/ChatTariffs';
import { VideoTariffs } from './components/VideoTariffs';

const API_ENDPOINT = `${process.env.REACT_APP_API_URL}/api/v1/public/expert/self-registration`;

const formatToBackendCompatibleJSON = (data: ConfiguredForm['values']) => {
  // Format the non-array fields.
  let result: any = {
    full_name: data.full_name,
    phone: data.phone.replaceAll(/[()\s\-\_]/g, ''),
    dob: dayjs(data.dob, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    specialisation: data.specialisation,
    experience: data.experience,
    education: data.education,
    about: data.about,
    tariffs: {
      chats: [],
      videos: [],
    },
    schedulers: [],
  };
  // Format tariffs
  result.tariffs.chats = data.chat.map((tariff, index) => ({
    is_active: tariff.is_active,
    name: tariff.is_active ? tariff.name : null,
    duration: Number(tariff.duration),
    price: Number(tariff.price),
    is_default: index === 0,
  }));
  result.tariffs.videos = data.video.map((tariff, index) => ({
    is_active: tariff.is_active,
    name: tariff.is_active ? tariff.name : null,
    duration: Number(tariff.duration),
    price: Number(tariff.price),
    is_default: index === 0,
  }));
  // Format schedule
  result.schedulers = data.schedule.map(item => {
    return {
      index_day: item.index_day,
      is_active: item.is_active,
      from: item.from ? dayjs(item.from).format('HH:mm') : null,
      to: item.to ? dayjs(item.to).format('HH:mm') : null,
    };
  });
  return result;
};

const App = () => {
  const [step, setStep] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [result, setResult] = useState<'success' | 'failure' | null>(null);
  const form = useFormWithConfig();
  const onSubmit = async () => {
    const formattedValues = formatToBackendCompatibleJSON(form.values);
    try {
      setIsFetching(true);
      const result = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formattedValues),
      });
      if (result.status === 200) {
        setResult('success');
      } else {
        setResult('failure');
      }
    } catch (error) {
      setResult('failure');
    } finally {
      setStep(5);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [step]);

  const progressProps = useMemo(() => {
    const title = step === 5 ? (result === 'success' ? 'Закончили' : 'Упс') : `Шаг ${step + 1}`;
    let subtitle = '';
    switch (step) {
      case 0:
        subtitle = 'Аккаунт';
        break;
      case 1:
        subtitle = 'О вас';
        break;
      case 2:
        subtitle = 'Видеоконсультации';
        break;
      case 3:
        subtitle = 'Чат';
        break;
      case 4:
        subtitle = 'Расписание';
        break;
      case 5:
        subtitle = result === 'success' ? 'Встретимся в приложении!' : 'Что-то пошло не так';
        break;
      default:
        break;
    }

    return {
      title,
      subtitle,
    };
  }, [step, result]);
  return (
    <>
      <Image
        fit={'cover'}
        width={'100%'}
        height={192}
        src={'./assets/header.png'}
        alt={'Yapomogu app'}
      />
      <Container style={{ position: 'relative' }}>
        <Group mt="xl" spacing={8} position={'left'}>
          <RingProgress
            size={68}
            thickness={6}
            roundCaps
            sections={[{ value: (step / 5) * 100, color: 'blue' }]}
            label={
              <Text color="blue" weight={700} align="center" size="xs">
                {`${step}/5`}
              </Text>
            }
          />
          <Stack spacing={0}>
            <Text weight={'bold'}>{progressProps.title}</Text>
            <Text>{progressProps.subtitle}</Text>
          </Stack>
        </Group>
        <Container my="xl" px={0}>
          {step === 0 && <AuthInformation setStep={setStep} form={form} />}
          {step === 1 && <PersonalInformation setStep={setStep} form={form} />}
          {step === 2 && <VideoTariffs setStep={setStep} form={form} />}
          {step === 3 && <ChatTariffs setStep={setStep} form={form} />}
          {step === 4 && <Schedule onSubmit={onSubmit} form={form} />}
          {step === 5 && result && <RegistrationResult variant={result} />}
        </Container>
        <LoadingOverlay visible={isFetching} loaderProps={{ color: 'blue' }} />
      </Container>
    </>
  );
};

export default App;
