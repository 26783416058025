import { formList, useForm } from '@mantine/form';
import dayjs from 'dayjs';
// Utility
const scheduleDays = new Array(7).fill(true).map((_, index) => {
  return {
    index_day: index + 1,
    is_active: false,
    from: undefined,
    to: undefined,
  };
});
// Utility end

const initialValues = {
  // First page
  full_name: '',
  phone: '',
  dob: '',
  specialisation: '',
  experience: '',
  education: '',
  about: '',
  // Video Tariffs
  video: formList([
    {
      name: '',
      duration: '',
      price: '',
      is_active: true,
    },
    {
      name: '',
      duration: '',
      price: '',
      is_active: true,
    },
  ]),
  // Chat Tariffs
  chat: formList([
    {
      name: '',
      duration: '',
      price: '',
      is_active: true,
    },
    {
      name: '',
      duration: '',
      price: '',
      is_active: true,
    },
  ]),
  // Schedule,
  schedule: formList(scheduleDays),
};

const requiredField = (value: any, length: number | void) => {
  if (!value) return 'Обязательное поле';
  if (length) {
    if (value.length >= length) {
      return `Не более ${length} символов`;
    }
  }
  return value.length < 1 ? 'Обязательное поле' : null;
};

const validatePhoneNumber = (value: string) => {
  if (!value) {
    return 'Обязательное поля';
  }
  // Strip all placeholder _ and ()
  const clean = value.replaceAll(/[()\s\-\_]/g, '');
  // +71234567890
  if (clean.length !== 12) {
    return 'Проверьте введенный номер';
  }
  return null;
};

const validateDateOfBirth = (value: string) => {
  if (!value) return 'Обязательное поле';
  let parsedDate = dayjs(value, 'DD.MM.YYYY', true);
  if (!parsedDate.isValid()) {
    return 'Проверьте формат введенных данных';
  }
  let userAge = dayjs().diff(parsedDate, 'years');
  if (userAge < 18) {
    return 'Вы должны быть старше 18 лет';
  }
  return null;
};

type ValidateTariffsParams<T> = T extends 'chat'
  ? typeof initialValues['chat']
  : typeof initialValues['video'];

function validateTariffs<T>(type: T, tariffs: ValidateTariffsParams<T>) {
  let errors = {} as any;
  tariffs.forEach((item, index) => {
    const prefix = `${type}.${index}`;
    // Validate only enabled tariff.
    if (item.is_active) {
      if (!item.name) {
        errors[prefix + '.name'] = 'Обязательное поле';
      }
      if (!item.duration) {
        errors[prefix + '.duration'] = 'Обязательное поле';
      }
      if (!item.price) {
        errors[prefix + '.price'] = 'Обязательное поле';
      }
      if (Number.isNaN(item.price)) {
        errors[prefix + '.price'] = 'Проверьте формат данных';
      }
    }
  });
  return errors;
}

const validateSchedule = (schedule: typeof initialValues['schedule']) => {
  // Return an schedule.${index}.field object with error text.
  const errors = {} as any;
  // At least one day should be active.
  if (!schedule.some(item => item.is_active)) {
    errors.schedule = 'Должен быть хотя бы один активный день в расписании';
    return errors;
  }
  schedule.forEach((item, index) => {
    let prefix = `schedule.${index}`;
    // Ignore the days which are not active.
    if (!item.is_active) return;
    // Both fields should be filled.
    if (!item.from) {
      errors[prefix + '.from'] = 'Обязательное поле';
    }
    if (!item.to) {
      errors[prefix + '.to'] = 'Обязательное поле';
    }
    if (item.from && item.to) {
      // to should be always after from
      if (dayjs(item.from).isAfter(item.to)) {
        errors[prefix + '.to'] = 'Не можем закончить раньше, чем начали';
      }
    }
  });
  return errors;
};

const validateValues = (values: typeof initialValues) => {
  const chatErrors = validateTariffs<'chat'>('chat', values.chat);
  const videoErrors = validateTariffs<'video'>('video', values.video);
  const scheduleErrors = validateSchedule(values.schedule);

  return {
    full_name: requiredField(values.full_name),
    phone: validatePhoneNumber(values.phone),
    dob: validateDateOfBirth(values.dob),
    specialisation: requiredField(values.specialisation),
    experience: requiredField(values.experience),
    education: requiredField(values.education, 150),
    ...chatErrors,
    ...videoErrors,
    ...scheduleErrors,
  };
};

export const useFormWithConfig = () => {
  return useForm({
    initialValues,
    validate: values => validateValues(values),
  });
};

export type ConfiguredForm = ReturnType<typeof useFormWithConfig>;
